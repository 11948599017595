/**
 * ProductSalesMarginAccordion Component
 *
 * This component manages the sales margin interface for products, handling both NRC (Non-Recurring Charges)
 * and MRC (Monthly Recurring Charges). It displays budgetary vs. firm pricing and allows users to:
 * - View and manage multiple quotes in chronological order (newest first)
 * - Create new quotes when previous ones are sent
 * - Set margins in both absolute (€) and relative (%) terms
 * - Add notes for customers
 * - Save, confirm, and send quotes based on their state
 *
 * The quote workflow follows these states:
 * Draft -> Confirmed -> Sent -> (New Quote possible)
 */
import { useState, useMemo, useCallback, memo } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'

import {
  ProductSalesMarginAccordionProps,
  PriceCalculation,
} from './ProductSalesMarginTypes'
import { QuoteState } from '../../../types/enums/QuoteState'
import {
  useQuoteManagement,
  useMarginCalculation,
  useQuoteActions,
} from './ProductSalesMarginHooks'
import { ProductSalesMarginHeader } from './ProductSalesMarginHeader.component'
import { QuoteTabsNavigation } from './QuoteTabsNavigation.component'
import { ProductSalesMarginForm } from './ProductSalesMarginForm.component'

/**
 * Main component that manages sales margin for products
 * Coordinates between child components and manages state
 */
export const ProductSalesMarginAccordion: React.FC<ProductSalesMarginAccordionProps> =
  memo(({ lastRecalculationRequestTimestamp }) => {
    // State for accordion expansion
    const [isExpanded, setIsExpanded] = useState(false)

    // Use custom hooks for business logic
    const {
      record,
      recordQuotes,
      activeQuoteIndex,
      setActiveQuoteIndex,
      isCreatingNewQuote,
      setIsCreatingNewQuote,
      findPreferredQuoteIndex,
      getActualIndexFromDisplayIndex,
      getDisplayIndexFromActualIndex,
      handleCreateNewQuote,
      handleCancelNewQuote,
      lastRecalculationTimestamp,
    } = useQuoteManagement(lastRecalculationRequestTimestamp)

    const {
      isLoading,
      setIsLoading,
      priceCalculation,
      notesForClient,
      onChangeMrcRelativeMargin,
      onChangeNrcRelativeMargin,
      onChangeMrcAbsoluteMargin,
      onChangeNrcAbsoluteMargin,
      onChangeNotesForClient,
      resetMarginValues,
      onBlurMarginField,
      recalculatePrice,
    } = useMarginCalculation(
      record,
      isCreatingNewQuote,
      activeQuoteIndex,
      recordQuotes,
      lastRecalculationTimestamp
    )

    const { handleConfirm, handleSend, handleSave } = useQuoteActions(
      record,
      priceCalculation,
      notesForClient,
      isCreatingNewQuote,
      setIsCreatingNewQuote,
      findPreferredQuoteIndex,
      setActiveQuoteIndex,
      setIsLoading
    )

    // Toggle accordion expansion
    const toggleAccordion = useCallback(() => {
      setIsExpanded(!isExpanded)
    }, [isExpanded])

    // Handle tab change
    const handleTabChange = useCallback(
      (_event: React.SyntheticEvent, newDisplayIndex: number) => {
        if (newDisplayIndex === recordQuotes.length && !isCreatingNewQuote) {
          handleCreateNewQuote()
          resetMarginValues()
          recalculatePrice()
          return
        }

        if (isCreatingNewQuote && newDisplayIndex !== recordQuotes.length) {
          setIsCreatingNewQuote(false)
        }

        if (isCreatingNewQuote) {
          return
        }

        const newActualIndex = getActualIndexFromDisplayIndex(newDisplayIndex)
        setActiveQuoteIndex(newActualIndex)

        if (newDisplayIndex < recordQuotes.length) {
          const quote = recordQuotes[newActualIndex]
          if (quote) {
            // Update price calculation and notes from the selected quote
          }
        }
      },
      [
        recordQuotes,
        isCreatingNewQuote,
        handleCreateNewQuote,
        resetMarginValues,
        recalculatePrice,
        setIsCreatingNewQuote,
        getActualIndexFromDisplayIndex,
        setActiveQuoteIndex,
      ]
    )

    // Check if the current quote is editable
    const isQuoteEditable = useMemo(() => {
      if (isCreatingNewQuote || recordQuotes.length === 0) {
        return true
      }

      const currentQuote = recordQuotes[activeQuoteIndex]
      return currentQuote?.state === 'draft'
    }, [recordQuotes, activeQuoteIndex, isCreatingNewQuote])

    // Prepare calculation data for forms
    const calculationData = useMemo(
      () => ({
        budgetary: record?.priceCalculation || ({} as PriceCalculation),
        firm: priceCalculation || ({} as PriceCalculation),
        record,
      }),
      [record, priceCalculation]
    )

    // Get active quote and its state
    const activeQuote = recordQuotes[activeQuoteIndex]
    const quoteState = activeQuote?.state as QuoteState | undefined
    const displayActiveIndex = getDisplayIndexFromActualIndex(activeQuoteIndex)

    if (!record) return null

    return (
      <Accordion
        expanded={record.isCancelled ? false : isExpanded}
        disableGutters
        disabled={record.isCancelled}
      >
        <AccordionSummary
          expandIcon={<ExpandAccordionIcon color="info" />}
          onClick={toggleAccordion}
        >
          <ProductSalesMarginHeader
            isQuoteConfirmed={!!record.isQuoteConfirmed}
            isCancelled={!!record.isCancelled}
            marginBookId={calculationData.firm?.marginBookId}
            toggleAccordion={toggleAccordion}
          />
        </AccordionSummary>
        <AccordionDetails>
          {(recordQuotes.length > 0 || isCreatingNewQuote) && (
            <QuoteTabsNavigation
              quotes={recordQuotes}
              activeDisplayIndex={displayActiveIndex}
              isCreatingNewQuote={isCreatingNewQuote}
              onTabChange={handleTabChange}
              onCreateNewQuote={handleCreateNewQuote}
              getActualIndexFromDisplayIndex={getActualIndexFromDisplayIndex}
            />
          )}
          <ProductSalesMarginForm
            calculationData={calculationData}
            isQuoteEditable={isQuoteEditable}
            isLoading={isLoading}
            quoteState={quoteState}
            notesForClient={notesForClient}
            onChangeNrcAbsoluteMargin={onChangeNrcAbsoluteMargin}
            onChangeNrcRelativeMargin={onChangeNrcRelativeMargin}
            onChangeMrcAbsoluteMargin={onChangeMrcAbsoluteMargin}
            onChangeMrcRelativeMargin={onChangeMrcRelativeMargin}
            onChangeNotesForClient={onChangeNotesForClient}
            onBlurMarginField={onBlurMarginField}
            onConfirm={handleConfirm}
            onSend={handleSend}
            onSave={handleSave}
            onCancel={isCreatingNewQuote ? handleCancelNewQuote : undefined}
          />
        </AccordionDetails>
      </Accordion>
    )
  })
