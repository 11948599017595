import React from 'react'
import { IRequestSiteFilters } from './RequestSiteFilters.types'
import {
  Filter,
  TextInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
} from 'react-admin'

/**
 * Filters for a list of request sites.
 * @property {IRequestSiteFilters} props The properties of the component.
 * @returns {JSX.Element} The JSX to render.
 * @example
 * <RequestSiteFilters />
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 * @see https://marmelab.com/react-admin/List.html#the-filter-form
 * @see https://marmelab.com/react-admin/List.html#the-filter-button
 */
export const RequestSiteFilters: React.FC<IRequestSiteFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Term" source="term" alwaysOn />
      <ReferenceInput source="countryCode" reference="countries/list" alwaysOn>
        <AutocompleteArrayInput label={'Country'} optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="clientIds" reference="clients/list" alwaysOn>
        <AutocompleteArrayInput
          label="Customer"
          optionText="name"
          alwaysOn
          sx={{ minWidth: 200 }}
        />
      </ReferenceInput>
      <SelectArrayInput
        source="state"
        label="State"
        choices={[
          { id: 'pending', name: 'Pending' },
          { id: 'incomplete', name: 'Incomplete' },
          { id: 'complete', name: 'Complete' },
          { id: 'confirmed', name: 'Confirmed' },
        ]}
        alwaysOn
      />
    </Filter>
  )
}
