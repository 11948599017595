import React, { useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import {
  ArrayField,
  Datagrid,
  DateField,
  SimpleShowLayout,
  useGetOne,
  useRecordContext,
  SimpleForm,
  TextInput,
  useRefresh,
  useNotify,
  useDataProvider,
  TextField,
  Button,
  Link,
  required,
  SelectInput,
  ListBase,
  useListContext,
} from 'react-admin'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ServiceHealthIndicatorField } from '../../atoms/ServiceHealthIndicatorField'
import { TypeStateField } from '../../atoms/TypeStateField'
import { ReferenceChip } from '../ReferenceChip'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import { FieldValues } from 'react-hook-form'
import { DateInput } from '../../atoms/DateInput'
import { DownloadFileButton } from '../../atoms/DownloadFileButton'
import { SendFile } from '../SendFile/SendFile.component'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete'

enum ClientContractState {
  Draft = 'draft',
  Confirmed = 'confirmed',
  Active = 'active',
  Cancelled = 'cancelled',
  Ended = 'ended',
  Replaced = 'replaced',
  Archived = 'archived',
}

export const ServiceDetailsAccordion: React.FC = () => {
  const record = useRecordContext()
  const [isExpanded, setIsExpanded] = React.useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isLoading, setIsLoading] = React.useState(false)

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  const { data: service } = useGetOne(
    `services`,
    {
      id: record?.quotes[0]?.fulfilledServiceId,
    },
    { enabled: !!record?.quotes[0]?.fulfilledServiceId }
  )

  useEffect(() => {
    if (
      record?.quotes[0]?.isOrderConfirmed ||
      (record?.quotes[0]?.isFulfilled && record?.quotes[0]?.fulfilledServiceId)
    ) {
      setIsExpanded(true)
    }
  }, [record.quotes])

  const handleFulfill = async (data: FieldValues) => {
    setIsLoading(true)
    try {
      const fulfilledOrder = await dataProvider.customRequest(
        'quotes',
        `${record.quotes[0].id}/fulfillOrder`,
        {
          data: {
            name: data.serviceName,
            deliveryWishDate: data.deliveryWishDate,
            contractState: data.contractState ?? 'draft',
          },
          method: 'POST',
        }
      )
      if (fulfilledOrder?.json?.data?.id) {
        notify('Order has been fulfilled')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
    setIsExpanded(false)
  }

  if (!record) return null

  const DocumentList = () => {
    const { refetch } = useListContext({
      sort: { field: 'createdAt', order: 'DESC' },
    })

    useEffect(() => {
      const timer = setTimeout(() => {
        refetch()
      }, 5000)

      return () => clearTimeout(timer)
    }, [refetch])

    return (
      <Datagrid bulkActionButtons={false}>
        <TextField source="documentNo" />
        <ArrayField source="files" emptyText="Generating PDF...">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <DateField source="createdAt" showTime />
            <DownloadFileButton resource="files" />
            <SendFile hasRelatedEntity />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    )
  }

  return (
    <Accordion
      expanded={record.isCancelled ? false : isExpanded}
      disableGutters
      disabled={record.isCancelled}
    >
      <AccordionSummary
        expandIcon={<ExpandAccordionIcon color="info" />}
        onClick={toggleAccordion}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" alignItems="center" display="flex" gap={1}>
              {record?.quotes[0]?.isFulfilled ? (
                <DoneIcon sx={{ mr: 1 }} />
              ) : (
                <ToDoIcon sx={{ mr: 1 }} />
              )}{' '}
              Order: Service Creation
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {record?.quotes[0]?.isOrderConfirmed ? (
        <AccordionDetails>
          {record?.quotes[0]?.isFulfilled &&
          record?.quotes[0]?.fulfilledServiceId &&
          service ? (
            <Stack spacing={2}>
              <Link
                to={`/services/${record?.quotes[0]?.fulfilledServiceId}/show`}
              >
                <SimpleShowLayout sx={{ p: 0, m: 0 }}>
                  <ArrayField
                    source="quotes"
                    label={false}
                    filter={{
                      id: record?.quotes[0]?.id,
                    }}
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="serviceNo" record={service} />
                      <TypeStateField
                        source="state"
                        fullWidth
                        record={service}
                      />
                      <ServiceHealthIndicatorField
                        onlyIcon
                        source="health"
                        label="Health"
                        sortable={false}
                        record={service}
                      />
                      <EllipsedTextField source="name" record={service} />
                      <ReferenceChip
                        referenceSource="clientId"
                        tooltipSource="clientNo"
                        source="clientId"
                        label="Client"
                        reference="clients"
                        emptyText="N/A"
                        link="show"
                        chipSource="name"
                        record={service}
                      />
                      <ReferenceChip
                        referenceSource="vendorId"
                        tooltipSource=""
                        source="vendorId"
                        label="Vendor"
                        reference="vendors"
                        emptyText="N/A"
                        link="show"
                        chipSource="name"
                        record={service}
                      />
                      <DateField source="createdAt" showTime record={service} />
                    </Datagrid>
                  </ArrayField>
                </SimpleShowLayout>
              </Link>
              <ListBase
                resource="documents"
                filter={{ serviceId: service?.id, 'category.key': 'CSO' }}
                sort={{ field: 'createdAt', order: 'DESC' }}
              >
                <DocumentList />
              </ListBase>
            </Stack>
          ) : (
            <SimpleForm
              onSubmit={handleFulfill}
              toolbar={
                <Box textAlign="right" marginBottom={1} marginX={2}>
                  <Button
                    size="small"
                    label="Create service and CSO"
                    type="submit"
                    variant="contained"
                    color="info"
                    startIcon={<CheckCircleIcon />}
                    disabled={isLoading}
                  />
                </Box>
              }
              defaultValues={{
                serviceName: record.name,
              }}
            >
              <TextInput
                fullWidth
                variant="outlined"
                source="serviceName"
                label="Service name"
                validate={required()}
                resettable
              />
              <DateInput
                source="deliveryWishDate"
                variant="outlined"
                fullWidth
              />
              <SelectInput
                fullWidth
                variant="outlined"
                source="contractState"
                label="Contract state"
                choices={Object.values(ClientContractState).map((key) => ({
                  id: key,
                  name: key,
                }))}
              />
              <ReferenceAutocomplete
                label="GNX Entity"
                source={'entityId'}
                reference="entities/list"
                defaultValue={record.client?.entityId}
              />
            </SimpleForm>
          )}
        </AccordionDetails>
      ) : (
        <AccordionDetails>
          <Typography color="text.secondary" variant="body1" p={2}>
            Order has not been confirmed (yet)
          </Typography>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
