import React from 'react'
import { ILogo } from './Logo.types'
import Tooltip from '@mui/material/Tooltip'

/**
 * GNX Logo wrapper
 * @prop {number} height - The height of the logo
 */
export const Logo: React.FC<ILogo> = (props) => {
  const logoImage = require('../../../logo.png')
  return (
    <Tooltip
      title={`plus-manager version ${
        process.env.REACT_APP_VERSION || 'development'
      }`}
    >
      <img src={logoImage} alt="GNX Logo" {...props} />
    </Tooltip>
  )
}
