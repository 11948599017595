import { useState, useCallback, useMemo, useEffect } from 'react'
import {
  useRecordContext,
  useRefresh,
  useNotify,
  useDataProvider,
} from 'react-admin'
import { RecordContext, QuoteData } from './ProductSalesMarginTypes'
import {
  findPreferredQuoteIndex as findPreferredQuoteIndexUtil,
  getActualIndexFromDisplayIndex as getActualIndexFromDisplayIndexUtil,
  getDisplayIndexFromActualIndex as getDisplayIndexFromActualIndexUtil,
  calculateAbsoluteMargin,
  calculateRelativeMargin,
} from './ProductSalesMarginUtils'

/**
 * Custom hook to manage quote selection and navigation
 */
export const useQuoteManagement = (
  lastRecalculationRequestTimestamp?: number
) => {
  const record = useRecordContext<RecordContext>()
  const recordQuotes = useMemo(() => record?.quotes || [], [record?.quotes])
  const [activeQuoteIndex, setActiveQuoteIndex] = useState(0)
  const [isCreatingNewQuote, setIsCreatingNewQuote] = useState(false)
  const [initialSelectionMade, setInitialSelectionMade] = useState(false)
  const [lastRecalculationTimestamp, setLastRecalculationTimestamp] = useState(
    lastRecalculationRequestTimestamp ?? Date.now()
  )

  /**
   * Finds the most appropriate quote to display based on priority
   */
  const findPreferredQuoteIndex = useCallback(() => {
    return findPreferredQuoteIndexUtil(recordQuotes)
  }, [recordQuotes])

  /**
   * Converts display index to actual index
   */
  const getActualIndexFromDisplayIndex = useCallback(
    (displayIndex: number): number => {
      return getActualIndexFromDisplayIndexUtil(
        displayIndex,
        recordQuotes.length
      )
    },
    [recordQuotes.length]
  )

  /**
   * Converts actual index to display index
   */
  const getDisplayIndexFromActualIndex = useCallback(
    (actualIndex: number): number => {
      return getDisplayIndexFromActualIndexUtil(
        actualIndex,
        recordQuotes.length
      )
    },
    [recordQuotes.length]
  )

  /**
   * Creates a new quote by resetting all margin values
   */
  const handleCreateNewQuote = useCallback(() => {
    setIsCreatingNewQuote(true)
  }, [])

  /**
   * Cancels the creation of a new quote and returns to the preferred quote
   */
  const handleCancelNewQuote = useCallback(() => {
    setIsCreatingNewQuote(false)
    const preferredIndex = findPreferredQuoteIndex()
    setActiveQuoteIndex(preferredIndex)
  }, [findPreferredQuoteIndex])

  // Update active quote when record changes
  useEffect(() => {
    if (!record) return

    if (recordQuotes.length > 0) {
      if (activeQuoteIndex >= recordQuotes.length) {
        setActiveQuoteIndex(findPreferredQuoteIndex())
      } else if (!initialSelectionMade) {
        setActiveQuoteIndex(findPreferredQuoteIndex())
        setInitialSelectionMade(true)
      }
    }
  }, [
    record,
    recordQuotes,
    activeQuoteIndex,
    findPreferredQuoteIndex,
    initialSelectionMade,
  ])

  // Update recalculation timestamp when it changes
  useEffect(() => {
    if (
      lastRecalculationRequestTimestamp &&
      lastRecalculationRequestTimestamp > lastRecalculationTimestamp
    ) {
      setLastRecalculationTimestamp(lastRecalculationRequestTimestamp)
    }
  }, [lastRecalculationRequestTimestamp, lastRecalculationTimestamp])

  return {
    record,
    recordQuotes,
    activeQuoteIndex,
    setActiveQuoteIndex,
    isCreatingNewQuote,
    setIsCreatingNewQuote,
    findPreferredQuoteIndex,
    getActualIndexFromDisplayIndex,
    getDisplayIndexFromActualIndex,
    handleCreateNewQuote,
    handleCancelNewQuote,
    lastRecalculationTimestamp,
  }
}

/**
 * Custom hook to manage margin calculations and price updates
 */
export const useMarginCalculation = (
  record: RecordContext | undefined,
  isCreatingNewQuote: boolean,
  activeQuoteIndex: number,
  recordQuotes: QuoteData[],
  lastRecalculationTimestamp: number
) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [isLoading, setIsLoading] = useState(false)
  const [nrcAbsoluteMargin, setNrcAbsoluteMargin] = useState<number>()
  const [mrcAbsoluteMargin, setMrcAbsoluteMargin] = useState<number>()
  const [nrcRelativeMargin, setNrcRelativeMargin] = useState<number>()
  const [mrcRelativeMargin, setMrcRelativeMargin] = useState<number>()
  const [priceCalculation, setPriceCalculation] =
    useState<Record<string, any>>()
  const [notesForClient, setNotesForClient] = useState('')
  const [lastChangedMrcMargin, setLastChangedMrcMargin] = useState<{
    type: 'marginAbsoluteMrc' | 'marginRelativeMrc' | null
    value: number | undefined
  }>({ type: null, value: undefined })
  const [lastChangedNrcMargin, setLastChangedNrcMargin] = useState<{
    type: 'marginAbsoluteNrc' | 'marginRelativeNrc' | null
    value: number | undefined
  }>({ type: null, value: undefined })
  const [mounted, setMounted] = useState(false)
  const [prevRecalculationTimestamp, setPrevRecalculationTimestamp] = useState(
    lastRecalculationTimestamp
  )

  /**
   * Recalculates prices based on current margin settings
   * Called automatically when margins are updated
   */
  const recalculatePrice = useCallback(async () => {
    if (!record) return

    setIsLoading(true)

    try {
      let input: string | undefined

      if (
        mrcAbsoluteMargin ||
        nrcAbsoluteMargin ||
        mrcRelativeMargin ||
        nrcRelativeMargin ||
        isCreatingNewQuote
      ) {
        input = 'custom'
      }

      // Prepare data object with only the required fields
      const data: Record<string, any> = { input }

      // Include the last changed margin values for MRC
      if (
        lastChangedMrcMargin.type &&
        lastChangedMrcMargin.value !== undefined
      ) {
        data[lastChangedMrcMargin.type] = lastChangedMrcMargin.value
      } else {
        data.marginAbsoluteMrc = mrcAbsoluteMargin
      }

      // Include the last changed margin values for NRC
      if (
        lastChangedNrcMargin.type &&
        lastChangedNrcMargin.value !== undefined
      ) {
        data[lastChangedNrcMargin.type] = lastChangedNrcMargin.value
      } else {
        data.marginAbsoluteNrc = nrcAbsoluteMargin
      }

      const result = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/recalculatePrice`,
        {
          data,
          method: 'PUT',
        }
      )
      if (result?.json?.data) {
        setPriceCalculation(result?.json?.data)
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
      setPriceCalculation(undefined)
    }
    setIsLoading(false)
  }, [
    mrcAbsoluteMargin,
    nrcAbsoluteMargin,
    mrcRelativeMargin,
    nrcRelativeMargin,
    lastChangedMrcMargin,
    lastChangedNrcMargin,
    dataProvider,
    notify,
    record,
    isCreatingNewQuote,
  ])

  // Input change handlers
  const onChangeMrcRelativeMargin = useCallback(
    (event: any) => {
      const newRelativeMargin = parseFloat(event.target.value)
      setMrcRelativeMargin(newRelativeMargin)

      setLastChangedMrcMargin({
        type: 'marginRelativeMrc',
        value: newRelativeMargin,
      })

      // Calculate corresponding absolute margin
      if (record?.priceCalculation?.purchaseValueMrc) {
        const newAbsoluteMargin = calculateAbsoluteMargin(
          record.priceCalculation.purchaseValueMrc,
          newRelativeMargin
        )
        setMrcAbsoluteMargin(newAbsoluteMargin)
      } else {
        setMrcAbsoluteMargin(undefined)
      }
    },
    [record?.priceCalculation?.purchaseValueMrc]
  )

  const onChangeNrcRelativeMargin = useCallback(
    (event: any) => {
      const newRelativeMargin = parseFloat(event.target.value)
      setNrcRelativeMargin(newRelativeMargin)

      setLastChangedNrcMargin({
        type: 'marginRelativeNrc',
        value: newRelativeMargin,
      })

      // Calculate corresponding absolute margin
      if (record?.priceCalculation?.purchaseValueNrc) {
        const newAbsoluteMargin = calculateAbsoluteMargin(
          record.priceCalculation.purchaseValueNrc,
          newRelativeMargin
        )
        setNrcAbsoluteMargin(newAbsoluteMargin)
      } else {
        setNrcAbsoluteMargin(undefined)
      }
    },
    [record?.priceCalculation?.purchaseValueNrc]
  )

  const onChangeMrcAbsoluteMargin = useCallback(
    (event: any) => {
      const newAbsoluteMargin = parseFloat(event.target.value)
      setMrcAbsoluteMargin(newAbsoluteMargin)

      setLastChangedMrcMargin({
        type: 'marginAbsoluteMrc',
        value: newAbsoluteMargin,
      })

      // Calculate corresponding relative margin
      if (record?.priceCalculation?.purchaseValueMrc) {
        const newRelativeMargin = calculateRelativeMargin(
          record.priceCalculation.purchaseValueMrc,
          newAbsoluteMargin
        )
        setMrcRelativeMargin(newRelativeMargin)
      } else {
        setMrcRelativeMargin(undefined)
      }
    },
    [record?.priceCalculation?.purchaseValueMrc]
  )

  const onChangeNrcAbsoluteMargin = useCallback(
    (event: any) => {
      const newAbsoluteMargin = parseFloat(event.target.value)
      setNrcAbsoluteMargin(newAbsoluteMargin)

      setLastChangedNrcMargin({
        type: 'marginAbsoluteNrc',
        value: newAbsoluteMargin,
      })

      // Calculate corresponding relative margin
      if (record?.priceCalculation?.purchaseValueNrc) {
        const newRelativeMargin = calculateRelativeMargin(
          record.priceCalculation.purchaseValueNrc,
          newAbsoluteMargin
        )
        setNrcRelativeMargin(newRelativeMargin)
      } else {
        setNrcRelativeMargin(undefined)
      }
    },
    [record?.priceCalculation?.purchaseValueNrc]
  )

  // Blur handlers - trigger price recalculation when field loses focus
  const onBlurMarginField = useCallback(() => {
    recalculatePrice()
  }, [recalculatePrice])

  const onChangeNotesForClient = useCallback((event: any) => {
    setNotesForClient(event.target.value)
  }, [])

  // Reset margin values when creating a new quote
  const resetMarginValues = useCallback(() => {
    setNrcAbsoluteMargin(undefined)
    setMrcAbsoluteMargin(undefined)
    setNrcRelativeMargin(undefined)
    setMrcRelativeMargin(undefined)
    setPriceCalculation(undefined)
    setNotesForClient('')
    setLastChangedMrcMargin({ type: null, value: undefined })
    setLastChangedNrcMargin({ type: null, value: undefined })
  }, [])

  // Update price calculation when component is first rendered
  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      recalculatePrice()
    }
  }, [mounted, recalculatePrice])

  // Call recalculatePrice when lastRecalculationTimestamp changes
  useEffect(() => {
    if (lastRecalculationTimestamp !== prevRecalculationTimestamp) {
      setPrevRecalculationTimestamp(lastRecalculationTimestamp)
      recalculatePrice()
    }
  }, [lastRecalculationTimestamp, prevRecalculationTimestamp, recalculatePrice])

  // Initialize margin values and update price calculation when record changes
  useEffect(() => {
    if (!record) return

    if (record.isQuoteConfirmed) {
      // For confirmed quotes, use the existing price calculation
      const activeQuote = recordQuotes[activeQuoteIndex]
      setPriceCalculation(activeQuote?.items?.[0]?.priceCalculation)
    } else {
      // For unconfirmed quotes, initialize margin values from the record or active quote
      const activeQuote = recordQuotes[activeQuoteIndex]
      const priceCalc =
        activeQuote?.items?.[0]?.priceCalculation || record?.priceCalculation

      if (priceCalc) {
        // Initialize MRC values
        if (priceCalc.marginAbsoluteMrc !== undefined) {
          setMrcAbsoluteMargin(priceCalc.marginAbsoluteMrc)
        }
        if (priceCalc.marginRelativeMrc !== undefined) {
          setMrcRelativeMargin(priceCalc.marginRelativeMrc)
        }

        // Initialize NRC values
        if (priceCalc.marginAbsoluteNrc !== undefined) {
          setNrcAbsoluteMargin(priceCalc.marginAbsoluteNrc)
        }
        if (priceCalc.marginRelativeNrc !== undefined) {
          setNrcRelativeMargin(priceCalc.marginRelativeNrc)
        }
      }
    }
  }, [record, recordQuotes, activeQuoteIndex])

  // Update notes for client when active quote changes
  useEffect(() => {
    if (!record) return

    const activeQuote = recordQuotes[activeQuoteIndex]
    const existingNotesForClient =
      activeQuote?.notesForClient ?? record?.vendorOffers?.[0]?.notesForClient

    if (existingNotesForClient) {
      setNotesForClient(existingNotesForClient)
    }
  }, [record, recordQuotes, activeQuoteIndex])

  return {
    isLoading,
    setIsLoading,
    nrcAbsoluteMargin,
    mrcAbsoluteMargin,
    nrcRelativeMargin,
    mrcRelativeMargin,
    priceCalculation,
    notesForClient,
    recalculatePrice,
    onChangeMrcRelativeMargin,
    onChangeNrcRelativeMargin,
    onChangeMrcAbsoluteMargin,
    onChangeNrcAbsoluteMargin,
    onChangeNotesForClient,
    resetMarginValues,
    onBlurMarginField,
  }
}

/**
 * Custom hook to manage quote actions (save, confirm, send)
 */
export const useQuoteActions = (
  record: RecordContext | undefined,
  priceCalculation: Record<string, any> | undefined,
  notesForClient: string,
  isCreatingNewQuote: boolean,
  setIsCreatingNewQuote: (value: boolean) => void,
  findPreferredQuoteIndex: () => number,
  setActiveQuoteIndex: (index: number) => void,
  setIsLoading: (value: boolean) => void
) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  /**
   * Handles quote confirmation by sending the current price calculation and notes to the backend
   * Updates the quote state to 'confirmed' if successful
   */
  const handleConfirm = useCallback(async () => {
    if (!record) return

    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/quote`,
        {
          data: { action: 'confirm', priceCalculation, notesForClient },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Margin has been confirmed')
        await refresh()
        setIsCreatingNewQuote(false)
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }, [
    record,
    dataProvider,
    priceCalculation,
    notesForClient,
    notify,
    refresh,
    setIsCreatingNewQuote,
    setIsLoading,
  ])

  /**
   * Sends the confirmed quote to the customer
   * Updates the quote state to 'sent' if successful
   */
  const handleSend = useCallback(async () => {
    if (!record) return

    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/quote`,
        {
          data: { action: 'send' },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Quote has been sent')
        refresh()
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }, [record, dataProvider, notify, refresh, setIsLoading])

  /**
   * Saves the current quote state without confirming
   * Creates a new quote if isCreatingNewQuote is true
   */
  const handleSave = useCallback(async () => {
    if (!record) return

    setIsLoading(true)

    try {
      const data = await dataProvider.customRequest(
        'requestProducts',
        `${record.id}/quote`,
        {
          data: { action: 'save', priceCalculation, notesForClient },
          method: 'PUT',
        }
      )
      if (data?.json?.data?.id) {
        notify('Changes have been saved')
        const wasCreatingNewQuote = isCreatingNewQuote

        await refresh()

        if (wasCreatingNewQuote) {
          setIsCreatingNewQuote(false)
          setActiveQuoteIndex(findPreferredQuoteIndex())
        }
      }
    } catch (error: any) {
      notify(error.message, { type: 'error' })
    }

    setIsLoading(false)
  }, [
    record,
    dataProvider,
    priceCalculation,
    notesForClient,
    notify,
    refresh,
    isCreatingNewQuote,
    setIsCreatingNewQuote,
    findPreferredQuoteIndex,
    setActiveQuoteIndex,
    setIsLoading,
  ])

  return {
    handleConfirm,
    handleSend,
    handleSave,
  }
}
