import React from 'react'
import {
  List,
  ListProps,
  TextField,
  BooleanField,
  DateField,
} from 'react-admin'
import { ReferenceText } from '../../molecules/ReferenceText'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'
import { ClientFilters } from '../../molecules/ClientFilters'

/**
 * Page to list clients
 * @property {ListProps} props
 * @example
 * <ClientList />
 * @returns {React.FC<ListProps>}
 * @see https://marmelab.com/react-admin/List.html
 */
export const ClientList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<ClientFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <LabelledDatagrid rowClick="show">
        <TextField source="clientNo" fullWidth />
        <TextField source="name" fullWidth />
        <ReferenceText
          source="countryCode"
          reference="countries/list"
          emptyText={'N/A'}
          link={false}
        />
        <ReferenceChip
          referenceSource="accountManagerId"
          tooltipSource="name"
          source="accountManagerId"
          chipSource="name"
          label={'Account Manager'}
          reference="users"
          emptyText={'N/A'}
          link="show"
          removeDoubleLabel
        />
        <ReferenceChip
          referenceSource="entityId"
          tooltipSource="name"
          source="entityId"
          chipSource="name"
          label={'GNX Entity'}
          reference="entities"
          emptyText={'Default'}
          link="show"
          removeDoubleLabel
        />
        <BooleanField source="isActive" fullWidth />
        <DateField source="updatedAt" showTime />
      </LabelledDatagrid>
    </List>
  )
}
