import { EntityShow } from './Show.component'
import { EntityList } from './List.component'
import { EntityCreate } from './Create.component'
import { EntityEdit } from './Edit.component'

export default {
  list: EntityList,
  show: EntityShow,
  create: EntityCreate,
  edit: EntityEdit,
}
