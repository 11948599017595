import React from 'react'
import {
  ChipField,
  DateField,
  FunctionField,
  RaRecord,
  RowClickFunction,
  TextField,
} from 'react-admin'
import { TypeStateField } from '../../atoms/TypeStateField'
import { EllipsedTextField } from '../../atoms/EllipsedField'
import { ReferenceChip } from '../ReferenceChip'
import { ConfigurableLabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceText } from '../ReferenceText'

export const RequestSiteDatagrid: React.FC = (props) => {
  const handleRowClick: RowClickFunction = (_id, _resource, record) => {
    return `/quote-requests/${record.requestId ?? ''}`
  }

  return (
    <ConfigurableLabelledDatagrid
      rowClick={handleRowClick}
      bulkActionButtons={false}
      expandSingle
      {...props}
    >
      <ReferenceChip
        referenceSource={'requestId'}
        tooltipSource="name"
        source="requestId"
        chipSource="requestNo"
        label={'Request'}
        reference="requests"
        emptyText={'N/A'}
        removeDoubleLabel
        link={(record) => `/quote-requests/${record.requestId}`}
        sortable
      />
      <TypeStateField source="state" emptyText="N/A" />
      <ReferenceText
        source="countryCode"
        reference="countries/list"
        emptyText={'N/A'}
        link={false}
      />
      <TextField source="address" emptyText="N/A" />
      <EllipsedTextField source="city" emptyText="N/A" />
      <FunctionField
        label="Services"
        render={(record: RaRecord) => (
          <ChipField
            link={`/quote-requests/${record.requestId ?? ''}`}
            size="small"
            record={{ value: record.services ? record.services.length : 0 }}
            source="value"
          />
        )}
      />
      <DateField source="updatedAt" showTime />
    </ConfigurableLabelledDatagrid>
  )
}
