import React from 'react'
import {
  Create,
  CreateProps,
  TabbedForm,
  FormTab,
  TextInput,
  required,
} from 'react-admin'

/**
 * Page to create an Entity.
 * @property {CreateProps} props
 * @example
 * <EntityCreate />
 * @returns {React.FC<CreateProps>}
 * @see https://marmelab.com/react-admin/Create.html
 */
export const EntityCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="address" fullWidth />
          <TextInput source="companyName" fullWidth />
          <TextInput source="companyRegNo" fullWidth />
          <TextInput source="companyVatNo" fullWidth />
          <TextInput source="companyIban" fullWidth />
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
