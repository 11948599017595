import { useState, useCallback, memo } from 'react'
import { Grid, Typography } from '@mui/material'
import {
  Button,
  SimpleForm,
  required,
  useCreate,
  useRecordContext,
} from 'react-admin'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SendIcon from '@mui/icons-material/SendOutlined'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import CancelIcon from '@mui/icons-material/Close'
import { ProductSalesMarginFormToolbarProps } from './ProductSalesMarginFormToolbar.types'
import { CreateDialog } from '@react-admin/ra-form-layout'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { NotesDialogToolbar } from '../../atoms/NotesDialogToolbar'
import { QuoteState } from '../../../types/enums/QuoteState'

export const ProductSalesMarginFormToolbar: React.FC<ProductSalesMarginFormToolbarProps> =
  memo((props) => {
    const { record } = useRecordContext()
    const [create] = useCreate()
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)

    const openCreateDialog = useCallback(() => {
      setIsCreateDialogOpen(true)
    }, [])

    const closeCreateDialog = useCallback(() => {
      setIsCreateDialogOpen(false)
    }, [])

    const submitForm = async (data: object) => {
      await create('notes', { data })
      closeCreateDialog()
      props.onConfirm()
    }

    return (
      <Grid container spacing={1} justifyContent="flex-end">
        {props.onCancel && (
          <Grid item>
            <Button
              size="small"
              label="Cancel"
              type="button"
              variant="outlined"
              color="error"
              onClick={props.onCancel}
              startIcon={<CancelIcon />}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            size="small"
            label="Save"
            type="button"
            variant="outlined"
            color="info"
            onClick={props.onSave}
            startIcon={<SaveIcon />}
            disabled={
              !props.onCancel &&
              props.activeQuoteState &&
              props.activeQuoteState !== QuoteState.Draft
            }
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            type="button"
            variant="contained"
            color="info"
            disabled={props.activeQuoteState !== QuoteState.Draft}
            onClick={openCreateDialog}
            startIcon={<CheckCircleIcon />}
            label="Confirm"
          />
          <CreateDialog
            fullWidth
            maxWidth="md"
            title={<Typography variant="h6">Add Note</Typography>}
            isOpen={isCreateDialogOpen}
            open={openCreateDialog}
            close={closeCreateDialog}
          >
            <SimpleForm
              defaultValues={{
                isInternal: true,
                relatedName: 'RequestProduct',
                relatedId: record?.id,
                clientId: record.request?.clientId,
              }}
              toolbar={<NotesDialogToolbar />}
              sx={{ paddingY: 0 }}
              onSubmit={submitForm}
            >
              <MarkdownInput
                height="200px"
                fullWidth
                label={false}
                source="content"
                validate={required()}
              />
            </SimpleForm>
          </CreateDialog>
        </Grid>
        <Grid item>
          <Button
            size="small"
            label="Send"
            type="button"
            onClick={props.onSend}
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            disabled={props.activeQuoteState !== QuoteState.Confirmed}
          />
        </Grid>
      </Grid>
    )
  })
