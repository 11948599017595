import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  required,
} from 'react-admin'

/**
 * Page to edit an Entity.
 * @property {EditProps} props
 * @example
 * <EntityEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const EntityEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <TextInput source="address" fullWidth />
          <TextInput source="companyName" fullWidth />
          <TextInput source="companyRegNo" fullWidth />
          <TextInput source="companyVatNo" fullWidth />
          <TextInput source="companyIban" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
