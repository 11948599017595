import React from 'react'
import { List, ListProps } from 'react-admin'
import { RequestSiteDatagrid } from '../../molecules/RequestSiteDatagrid/RequestSiteDatagrid.component'
import { RequestSiteFilters } from '../../molecules/RequestSiteFilters/RequestSiteFilters'

/** Page to display a list of request services. */
export const RequestSiteList: React.FC<Partial<ListProps>> = (props) => {
  const defaultFilter = {
    state: 'confirmed',
  }

  return (
    <List
      empty={false}
      filters={<RequestSiteFilters />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={defaultFilter}
      {...props}
    >
      <RequestSiteDatagrid />
    </List>
  )
}
