import { memo } from 'react'
import { SimpleForm, SimpleShowLayout } from 'react-admin'
import { Grid, Typography, Divider } from '@mui/material'
import { PriceTable } from './PriceTable.component'
import { CustomerNotesSection } from './CustomerNotesSection.component'
import { ProductSalesMarginFormToolbar } from './ProductSalesMarginFormToolbar.component'
import { ProductSalesMarginFormProps } from './ProductSalesMarginTypes'

/**
 * Component that renders the form for product sales margin
 * Contains price tables for NRC and MRC, and a customer notes section
 */
export const ProductSalesMarginForm: React.FC<ProductSalesMarginFormProps> =
  memo(
    ({
      calculationData,
      isQuoteEditable,
      isLoading,
      quoteState,
      notesForClient,
      onChangeNrcAbsoluteMargin,
      onChangeNrcRelativeMargin,
      onChangeMrcAbsoluteMargin,
      onChangeMrcRelativeMargin,
      onChangeNotesForClient,
      onBlurMarginField,
      onConfirm,
      onSend,
      onSave,
      onCancel,
    }) => {
      return (
        <SimpleForm
          record={calculationData}
          onSubmit={onConfirm}
          toolbar={
            <ProductSalesMarginFormToolbar
              onConfirm={onConfirm}
              onSend={onSend}
              onSave={onSave}
              onCancel={onCancel}
              isLoading={isLoading}
              activeQuoteState={quoteState}
            />
          }
          sx={{ padding: 0, '& legend': { display: 'none' } }}
        >
          <SimpleShowLayout
            sx={{ padding: 0, paddingBottom: 2, width: '100%' }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} xl={6}>
                <PriceTable
                  type="NRC"
                  budgetaryData={calculationData.budgetary}
                  firmData={calculationData.firm}
                  isEditable={isQuoteEditable}
                  onChangeAbsoluteMargin={onChangeNrcAbsoluteMargin}
                  onChangeRelativeMargin={onChangeNrcRelativeMargin}
                  onBlurMarginField={onBlurMarginField}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <PriceTable
                  type="MRC"
                  budgetaryData={calculationData.budgetary}
                  firmData={calculationData.firm}
                  isEditable={isQuoteEditable}
                  onChangeAbsoluteMargin={onChangeMrcAbsoluteMargin}
                  onChangeRelativeMargin={onChangeMrcRelativeMargin}
                  onBlurMarginField={onBlurMarginField}
                />
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="caption">
                  Note: sales prices exclude any taxes applicable between GNX
                  and customer
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <CustomerNotesSection
              notesForClient={notesForClient}
              isEditable={isQuoteEditable}
              onChangeNotesForClient={onChangeNotesForClient}
            />
          </SimpleShowLayout>
        </SimpleForm>
      )
    }
  )
