import React from 'react'
import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from '@react-admin/ra-navigation'
import { CardContent } from '@mui/material'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'

export const SettingsMenuItem: React.FC = () => {
  return (
    <IconMenu.Item name="settings" label="Settings" icon={<SettingsIcon />}>
      <CardContent>
        <MenuItemList>
          <MenuItemNode
            name="applications"
            to={'/applications'}
            label="Applications"
          />
          <MenuItemNode name="entities" to={'/entities'} label="Entities" />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  )
}
