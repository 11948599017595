import React from 'react'
import {
  Edit,
  EditProps,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  required,
  number,
  AutocompleteInput,
  ReferenceInput,
} from 'react-admin'
import { DocumentRow } from '../../organisms/DocumentRow'
import { DocumentModalInput } from '../../organisms/DocumentModalInput'
import { ReferenceManyFormTab } from '../../atoms/FormTab'

/**
 * Page to edit a client.
 * @property {EditProps} props
 * @example
 * <ClientEdit />
 * @returns {React.FC<EditProps>}
 * @see https://marmelab.com/react-admin/Edit.html
 */
export const ClientEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput disabled label="Id" source="id" fullWidth />
          <TextInput disabled label="clientNo" source="clientNo" fullWidth />
          <TextInput source="name" validate={required()} fullWidth />
          <BooleanInput source="isActive" fullWidth />
          <ReferenceInput
            source={'accountManagerId'}
            reference="users/list"
            filter={{ role: ['admin', 'gnx-admin'] }}
            emptyText={'N/A'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Account Manager'}
              fullWidth
            />
          </ReferenceInput>
          <ReferenceInput
            source={'entityId'}
            reference="entities/list"
            emptyText={'Default'}
          >
            <AutocompleteInput
              optionText="name"
              label={'GNX Entity'}
              fullWidth
            />
          </ReferenceInput>
        </FormTab>
        <FormTab label={'contact'}>
          <TextInput
            source="address"
            label="Full address"
            validate={required()}
            fullWidth
          />
          <TextInput source="postalCode" validate={required()} fullWidth />
          <ReferenceInput
            source={'countryCode'}
            reference="countries/list"
            emptyText={'N/A'}
          >
            <AutocompleteInput
              optionText="name"
              validate={required()}
              label={'Country'}
              fullWidth
            />
          </ReferenceInput>
          <TextInput source="city" validate={required()} fullWidth />
          <TextInput source="phoneNumber" fullWidth />
          <TextInput source="email" validate={required()} fullWidth />
        </FormTab>
        <FormTab label={'financial'}>
          <ReferenceInput
            source={'marginBookId'}
            reference="marginBooks/list"
            emptyText={'Default'}
          >
            <AutocompleteInput
              optionText="name"
              label={'Margin book'}
              fullWidth
            />
          </ReferenceInput>
          <TextInput source="companyName" validate={required()} fullWidth />
          <TextInput source="companyRegNo" fullWidth />
          <TextInput source="companyVatNo" fullWidth />
          <TextInput source="companyIban" fullWidth />
        </FormTab>
        <ReferenceManyFormTab
          reference="documents"
          target="clientId"
          label="documents"
        >
          <DocumentRow />
          <DocumentModalInput reference="clients" />
        </ReferenceManyFormTab>
        <FormTab label={'advanced'}>
          <TextInput
            source="jiraId"
            label="Jira organization ID"
            validate={[number()]}
            fullWidth
          />
          <BooleanInput source="syncJira" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
