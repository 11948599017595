import { memo } from 'react'
import { Grid, Typography } from '@mui/material'
import { ChipField, ReferenceField } from 'react-admin'
import DoneIcon from '@mui/icons-material/CheckBoxOutlined'
import ToDoIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { ProductSalesMarginHeaderProps } from './ProductSalesMarginTypes'

/**
 * Component that renders the header of the ProductSalesMarginAccordion
 * Shows the status (done/todo), title, and margin book reference
 */
export const ProductSalesMarginHeader: React.FC<ProductSalesMarginHeaderProps> =
  memo(({ isQuoteConfirmed, marginBookId }) => {
    return (
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6" alignItems="center" display="flex" gap={1}>
            {isQuoteConfirmed ? (
              <DoneIcon sx={{ mr: 1 }} />
            ) : (
              <ToDoIcon sx={{ mr: 1 }} />
            )}{' '}
            Sales: Confirm Margin
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="subtitle2">Margin Book</Typography>
            </Grid>
            <Grid item>
              <ReferenceField
                reference="marginBooks/list"
                source="marginBookId"
                record={{ marginBookId }}
              >
                <ChipField label={false} source="name" />
              </ReferenceField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  })
