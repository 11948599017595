import { memo } from 'react'
import { Box, Tabs, Tab, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { QuoteTabsNavigationProps } from './ProductSalesMarginTypes'

/**
 * Component that renders the tabs for navigating between quotes
 * Shows quote number, state, and price summary for each quote
 * Also includes a "New Quote" tab when applicable
 */
export const QuoteTabsNavigation: React.FC<QuoteTabsNavigationProps> = memo(
  ({
    quotes,
    activeDisplayIndex,
    isCreatingNewQuote,
    onTabChange,
    onCreateNewQuote,
    getActualIndexFromDisplayIndex,
  }) => {
    if (quotes.length === 0 && !isCreatingNewQuote) {
      return null
    }

    return (
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={isCreatingNewQuote ? quotes.length : activeDisplayIndex}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {[...quotes].reverse().map((quote, index) => (
            <Tab
              key={quote.id}
              label={
                <>
                  <Typography
                    variant="body2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Quote {index + 1} • {quote.state.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    NRC €
                    {quotes[getActualIndexFromDisplayIndex(index)]?.items?.[0]
                      ?.priceCalculation?.salesValueNrc || 0}{' '}
                    • MRC €
                    {quotes[getActualIndexFromDisplayIndex(index)]?.items?.[0]
                      ?.priceCalculation?.salesValueMrc || 0}
                  </Typography>
                </>
              }
              disabled={isCreatingNewQuote}
            />
          ))}
          {(quotes.some((quote) => quote.state === 'sent') ||
            isCreatingNewQuote) && (
            <Tab
              label={
                <Typography
                  variant="body2"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  <AddIcon sx={{ mr: 0.5 }} /> New Quote
                </Typography>
              }
              onClick={onCreateNewQuote}
              disabled={isCreatingNewQuote}
            />
          )}
        </Tabs>
      </Box>
    )
  }
)
