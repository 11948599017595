import React from 'react'
import { VendorFilters } from '../../molecules/VendorFilters'
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  BooleanField,
  TextField,
} from 'react-admin'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * Page to list all Vendors.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const VendorList: React.FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      empty={false}
      perPage={25}
      filters={<VendorFilters />}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" fullWidth />
        <ReferenceChip
          referenceSource="entityId"
          tooltipSource="name"
          source="entityId"
          chipSource="name"
          label={'GNX Entity'}
          reference="entities"
          emptyText={'Default'}
          link="show"
          removeDoubleLabel
        />
        <BooleanField source="isActive" fullWidth />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
