import React from 'react'
import {
  Filter,
  TextInput,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
} from 'react-admin'
import { IClientFilters } from './ClientFilters.types'

/**
 * @param {IClientFilters} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <ClientFilters />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-buttonform-combination|Filter Button/Filter Form Combination}
 */
export const ClientFilters: React.FC<IClientFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <BooleanInput source="isActive" label="Only show active" />
      <ReferenceInput
        source={'marginBookId'}
        reference="marginBooks/list"
        emptyText={'Default'}
      >
        <AutocompleteInput optionText="name" label={'Margin book'} fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source={'accountManagerId'}
        reference="users/list"
        filter={{ role: ['admin', 'gnx-admin'] }}
        emptyText={'N/A'}
      >
        <AutocompleteInput
          optionText="name"
          label={'Account Manager'}
          fullWidth
        />
      </ReferenceInput>
      <ReferenceInput
        source={'entityId'}
        reference="entities/list"
        label={'GNX Entity'}
        emptyText={'Default'}
      >
        <AutocompleteInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source={'countryCode'}
        reference="countries/list"
        emptyText={'N/A'}
      >
        <AutocompleteInput optionText="name" label={'Country'} fullWidth />
      </ReferenceInput>
    </Filter>
  )
}
