import IconSend from '@mui/icons-material/Send'
import IconCancel from '@mui/icons-material/Cancel'
import WarningIcon from '@mui/icons-material/Warning'
import { useState } from 'react'
import {
  Button,
  SaveButton,
  Form,
  useRecordContext,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  FormDataConsumer,
  required,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { FieldValues } from 'react-hook-form/dist/types/fields'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Box,
  Alert,
} from '@mui/material'
import { ReferenceAutocomplete } from '../ReferenceAutocomplete'
import { MarkdownInput } from '@react-admin/ra-markdown'

export const SendFile = ({
  hasRelatedEntity,
}: {
  hasRelatedEntity: boolean
}) => {
  const record = useRecordContext()
  const [showDialog, setShowDialog] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleClick = () => {
    if (!hasRelatedEntity) {
      setShowWarning(true)
    } else {
      setShowDialog(true)
    }
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleCloseWarning = () => {
    setShowWarning(false)
  }

  const handleSubmit = async (data: FieldValues) => {
    if (record.document?.state === 'final') {
      notify('Cannot (re-)send documents already in final state', {
        type: 'error',
      })
      return
    }

    setIsSubmitting(true)
    const ccGroupedByType = data.ccRecipients?.reduce(
      (
        acc: { [x: string]: string[] },
        item: { type: string | number; contactId: string }
      ) => {
        if (!acc[item.type]) {
          acc[item.type] = []
        }
        acc[item.type].push(item.contactId)

        return acc
      },
      {}
    )
    try {
      await dataProvider.customRequest(
        'documents',
        `${record.documentId}/send`,
        {
          data: {
            fileId: record.id,
            useEsigning: data.useEsigning,
            recipientSigneeId: {
              contactId: data.recipientSigneeId,
              type: 'client',
            },
            recipientSecondSigneeId: data.recipientSecondSigneeId
              ? {
                  contactId: data.recipientSecondSigneeId,
                  type: 'client',
                }
              : null,
            gnxSigneeId: { contactId: data.gnxSigneeId },
            ccRecipientsUsersIds: ccGroupedByType?.['user'],
            ccRecipientsClientsIds: ccGroupedByType?.['client'],
            ccRecipientsVendorsIds: ccGroupedByType?.['vendor'],
            textMessage: data.textMessage,
          },
          method: 'POST',
        }
      )
      notify('File sent successfully', {
        type: 'success',
      })
      setIsSubmitting(false)
      setShowDialog(false)
    } catch (error) {
      console.error(error)
      notify((error as any).message, {
        type: 'error',
      })

      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Send"
        disabled={record.document?.state === 'final'}
      >
        <IconSend />
      </Button>

      {/* Warning Dialog */}
      <Dialog open={showWarning} onClose={handleCloseWarning}>
        <DialogTitle>
          <Box display="flex" alignItems="center" color={'warning'}>
            <WarningIcon color="warning" /> Cannot Send File
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This file cannot be sent because this document is not attached to a
            contract.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarning} label="OK" />
        </DialogActions>
      </Dialog>

      {/* Send File Dialog */}
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth="md"
        onClose={handleCloseClick}
        aria-label="Upload File(s)"
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle>Send File</DialogTitle>
          <DialogContent>
            <ReferenceAutocomplete
              fullWidth
              inline
              required
              label="First customer signee"
              source={'recipientSigneeId'}
              reference="clientContacts/list"
              filter={{ clientId: record?.clientId }}
            />
            <ReferenceAutocomplete
              fullWidth
              inline
              label="Second customer signee (optional)"
              source={'recipientSecondSigneeId'}
              reference="clientContacts/list"
              filter={{ clientId: record?.clientId }}
            />
            <ReferenceAutocomplete
              fullWidth
              inline
              required
              label="GNX signee (usually Rutger)"
              filter={{ role: ['admin', 'gnx-admin'] }}
              source={'gnxSigneeId'}
              reference="users/list"
            />
            <ArrayInput source="ccRecipients" label="CC Recipients">
              <SimpleFormIterator inline fullWidth disableReordering>
                <FormDataConsumer>
                  {({ getSource, formData }) => {
                    if (
                      formData[`${getSource?.('type').split('.')[0]}`][
                        `${getSource?.('type').split('.')[1]}`
                      ]['type'] === 'user'
                    ) {
                      return (
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <SelectInput
                              source={getSource?.('type') || ''}
                              choices={[
                                { id: 'user', name: 'User' },
                                { id: 'contact', name: 'Contacts' },
                              ]}
                              validate={required()}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ReferenceAutocomplete
                              fullWidth
                              inline
                              required
                              label="CC Recipient"
                              source={getSource?.('contactId') || ''}
                              reference="users/list"
                            />
                          </Grid>
                        </Grid>
                      )
                    } else {
                      return (
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <SelectInput
                              source={getSource?.('type') || ''}
                              choices={[
                                { id: 'user', name: 'User' },
                                { id: 'client', name: 'Contact' },
                              ]}
                              validate={required()}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <ReferenceAutocomplete
                              fullWidth
                              inline
                              required
                              label="CC Recipient"
                              source={getSource?.('contactId') || ''}
                              reference="clientContacts/list"
                              filter={{ clientId: record?.clientId }}
                            />
                          </Grid>
                        </Grid>
                      )
                    }
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
            <Box my={2}>
              <Alert severity="info">
                Account manager will automatically be added as a CC recipient,
                no need to add them manually.
              </Alert>
            </Box>
            <MarkdownInput
              height="200px"
              fullWidth
              label={'Message to include (optional)'}
              source="textMessage"
            />
            <BooleanInput
              source="useEsigning"
              label="Use E-Signing"
              defaultValue={true}
            />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
            <SaveButton label="Send" disabled={isSubmitting}>
              <IconSend />
            </SaveButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}
