import React from 'react'
import { IconMenu } from '@react-admin/ra-navigation'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import TicketsIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import {
  AccessMenuItem,
  CatalogMenuItem,
  ClientsMenuItem,
  CoverageMenuItem,
  DocumentsMenuItem,
  MonitoringMenuItem,
  ServicesMenuItem,
  VendorsMenuItem,
  RequestsMenuItem,
  SettingsMenuItem,
} from '../../molecules/MenuItems'

/**
 * Main menu component
 * @returns {React.ReactElement}
 */
export const AppMenu: React.FC = () => {
  return (
    <IconMenu>
      <IconMenu.Item
        name="dashboard"
        icon={<DashboardIcon />}
        to="/"
        label="Dashboard"
      />
      <RequestsMenuItem />
      <ClientsMenuItem />
      <DocumentsMenuItem />
      <ServicesMenuItem />
      <CoverageMenuItem />
      <VendorsMenuItem />
      <CatalogMenuItem />
      <AccessMenuItem />
      <MonitoringMenuItem />
      <IconMenu.Item
        name="tickets"
        icon={<TicketsIcon />}
        to="/tickets"
        label="Tickets"
      />
      <SettingsMenuItem />
    </IconMenu>
  )
}
