import { MapContainer, useMap, GeoJSON, Popup } from 'react-leaflet'
import { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { useTileLayers } from './Map.hooks'
import { MapFeature, MapProps } from './Map.types'

import { featureCollection, feature } from '@turf/helpers'
import bbox from '@turf/bbox'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

L.Icon.Default.imagePath = 'images/'

export const Map: React.FC<MapProps> = (props) => {
  const record = useRecordContext()

  const [center, setCenter] = useState<[number, number]>([30, 30])
  const [zoom, setZoom] = useState<number>(1)
  const [viewport, setViewport] = useState<[number, number, number, number]>()
  const [features, setFeatures] = useState<MapFeature[]>([])
  const [loaded, setLoaded] = useState<boolean>(false)
  const [updateView, setUpdateView] = useState<boolean>(false)
  const layers = useTileLayers()

  useEffect(() => {
    if (props.viewport) {
      setViewport(props.viewport)
      setUpdateView(true)
    }
  }, [props.viewport])

  useEffect(() => {
    if (props.features) {
      setFeatures(props.features)
      setUpdateView(true)
    }
  }, [props.features])

  useEffect(() => {
    if (props.center) {
      if (props.center[0] !== center[0] && props.center[1] !== center[1])
        setCenter(props.center)
      setUpdateView(true)
    }
  }, [props.center, center])

  useEffect(() => {
    if (props.zoom && zoom !== props.zoom) {
      setZoom(props.zoom)
      setUpdateView(true)
    }
  }, [props.zoom, zoom])

  useEffect(() => {
    if (features.length === 0 && record) {
      if (record.geometry) {
        setFeatures([{ id: record.id, geometry: record.geometry }])
        setUpdateView(true)
      }
    }
  }, [features, record])

  const MapController = ({
    viewport,
    zoom,
  }: {
    viewport?: [number, number, number, number]
    center?: [number, number]
    zoom?: number
  }) => {
    const map = useMap()

    if (!updateView) return null

    if (features.length > 0) {
      const collection = featureCollection(
        features.map((g) => feature(g.geometry))
      )
      const bounds = bbox(collection)
      map.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ])
    } else if (viewport) {
      map.fitBounds([
        [Number(viewport[0]), Number(viewport[2])],
        [Number(viewport[1]), Number(viewport[3])],
      ])
    } else if (center) {
      let newZoomLevel = zoom || 1
      if (map.getZoom() > newZoomLevel) {
        newZoomLevel = map.getZoom()
      }
      map.setView(center, newZoomLevel)
    }

    if (loaded) return null

    setLoaded(true)
    map.setZoom(1)

    if (layers && !map.hasLayer(Object.values(layers)[0])) {
      Object.values(layers)[0].addTo(map)
      L.control.layers(layers).addTo(map)
    }

    return null
  }

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={true}
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        display: 'block',
        zIndex: 1,
        borderRadius: 15,
        ...props.style,
      }}
    >
      <MapController viewport={viewport} center={center} zoom={zoom} />
      {features
        ? features.map((g: MapFeature) => (
            <GeoJSON key={g.id} data={g.geometry}>
              {g.name ? <Popup>{g.name}</Popup> : null}
            </GeoJSON>
          ))
        : null}
    </MapContainer>
  )
}
