import React from 'react'
import { PageTitle } from '../../atoms/PageTitle'
import {
  Show,
  ShowProps,
  DateField,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  TabbedShowLayoutTabs,
  BooleanField,
  NumberField,
  ShowButton,
  ReferenceArrayField,
} from 'react-admin'

import { VendorContractsList } from '../../organisms/VendorContractsList'
import { IPageMap, RelatedResourcesPage } from '../../molecules/RelatedResource'
import { MutationsList } from '../../organisms/MutationsList'
import { DocumentList } from '../../organisms/DocumentList'
import { ServiceList } from '../../organisms/ServiceList'
import { ProductsList } from '../../organisms/ProductsList'
import { ContactsList } from '../../organisms/ContactsList'
import { LabelledDatagrid } from '../../atoms/LabelledDatagrid'
import { ReferenceChip } from '../../molecules/ReferenceChip'

/**
 * List of related pages to show in the "Related" tab
 */
const relatedPages: IPageMap = {
  Contacts: <ContactsList target="vendorId" reference="vendorContacts" />,
  Documents: <DocumentList target="vendorId" />,
  Contracts: <VendorContractsList target="vendorId" />,
  Services: <ServiceList target="vendorId" />,
  Products: <ProductsList target="vendorId" />,
}

/**
 * Page to show an existing Vendor.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const VendorShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Vendor" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <ReferenceChip
            referenceSource="entityId"
            tooltipSource="name"
            source="entityId"
            chipSource="name"
            label={'GNX Entity'}
            reference="entities"
            emptyText={'Default'}
            link="show"
            removeDoubleLabel
          />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="contact">
          <TextField
            source="address"
            label="Full address"
            emptyText="N/A"
            fullWidth
          />
          <TextField source="postalCode" emptyText="N/A" fullWidth />
          <ReferenceField
            source={'countryCode'}
            label={'Country'}
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          >
            <TextField source={'name'} />
          </ReferenceField>
          <TextField source="city" emptyText="N/A" fullWidth />
          <TextField source="phoneNumber" emptyText="N/A" fullWidth />
          <TextField source="email" emptyText="N/A" fullWidth />
        </Tab>
        <Tab label="financial">
          <TextField source="companyName" emptyText="N/A" fullWidth />
          <TextField
            source="companyRegNo"
            label="Business registration number"
            emptyText="N/A"
            fullWidth
          />
          <TextField
            source="companyVatNo"
            label="Taxes registration number"
            emptyText="N/A"
            fullWidth
          />
          <TextField
            source="companyIban"
            label="Bank account number"
            emptyText="N/A"
            fullWidth
          />
          <NumberField
            source="purchaseTaxRate"
            label="Purchase tax rate(%)"
            emptyText="N/A"
            fullWidth
          />
          <ReferenceField
            source={'taxOriginCountryCode'}
            label={'Tax country of origin (seller side)'}
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          >
            <TextField source={'name'} />
          </ReferenceField>
          <ReferenceField
            source={'taxTargetCountryCode'}
            label={'Tax target country (buyer side)'}
            reference="countries/list"
            emptyText={'N/A'}
            link={false}
          >
            <TextField source={'name'} />
          </ReferenceField>
          <TextField
            source={'taxTargetStateCode'}
            label={'Tax target state (buyer side)'}
            emptyText={'N/A'}
          />
        </Tab>
        <Tab label="lmps">
          <ReferenceArrayField
            reference="lastMileProviders"
            source="lastMileProviderIds"
            label={'Last Mile Providers'}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <LabelledDatagrid bulkActionButtons={false} rowClick="show">
              <TextField source="name" />
              <ShowButton resource={'lastMileProviders'} />
            </LabelledDatagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="isps">
          <ReferenceArrayField
            reference="internetServiceProviders"
            source="internetServiceProviderIds"
            label={'Internet Service Providers'}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <LabelledDatagrid bulkActionButtons={false} rowClick="show">
              <TextField source="name" />
              <ShowButton resource={'internetServiceProviders'} />
            </LabelledDatagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="api">
          <BooleanField
            source="apiConfig.isActive"
            label={'Enable API integration'}
          />
          <TextField
            source="apiConfig.queuePrefix"
            label={'Queue prefix to use for requests'}
            emptyText="N/A"
            fullWidth
          />
          <TextField
            source="apiConfig.queueKey"
            label={'Queue key to use for requests (overrides prefix)'}
            emptyText="N/A"
            fullWidth
          />
          <BooleanField
            source="apiConfig.supportsAvailabilityRequest"
            label={'Supports availability requests'}
          />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <RelatedResourcesPage resource="vendor" pages={relatedPages} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
