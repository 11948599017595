import React from 'react'
import {
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteArrayInput,
} from 'react-admin'
import { IVendorFilters } from './VendorFilters.types'

/**
 * @param {IVendorFilters} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 * @example
 * return (
 * <VendorFilters />
 * )
 * @see {@link https://marmelab.com/react-admin/List.html#the-filter-buttonform-combination|Filter Button/Filter Form Combination}
 */
export const VendorFilters: React.FC<IVendorFilters> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="term" alwaysOn />
      <ReferenceInput source="entityId" reference="entities/list" alwaysOn>
        <AutocompleteArrayInput label={'GNX Entity'} optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}
