/**
 * Utility functions for ProductSalesMargin calculations
 * These are pure functions extracted from the hooks for better testability
 */
import { QuoteState } from '../../../types/enums/QuoteState'
import { QuoteData, PriceCalculation } from './ProductSalesMarginTypes'

/**
 * Calculates the absolute margin based on a relative margin percentage
 * @param purchaseValue The purchase value to calculate from
 * @param relativeMargin The relative margin percentage
 * @returns The calculated absolute margin
 */
export const calculateAbsoluteMargin = (
  purchaseValue: number,
  relativeMargin: number
): number => {
  if (
    isNaN(purchaseValue) ||
    isNaN(relativeMargin) ||
    !purchaseValue ||
    !relativeMargin
  )
    return 0

  return (purchaseValue * relativeMargin) / (100 - relativeMargin)
}

/**
 * Calculates the relative margin percentage based on absolute margin
 * @param purchaseValue The purchase value
 * @param absoluteMargin The absolute margin amount
 * @returns The calculated relative margin percentage
 */
export const calculateRelativeMargin = (
  purchaseValue: number,
  absoluteMargin: number
): number => {
  if (
    isNaN(purchaseValue) ||
    isNaN(absoluteMargin) ||
    !purchaseValue ||
    !absoluteMargin
  )
    return 0
  if (purchaseValue + absoluteMargin === 0) return 0

  return (absoluteMargin / (purchaseValue + absoluteMargin)) * 100
}

/**
 * Calculates the sales value based on purchase value and absolute margin
 * @param purchaseValue The purchase value
 * @param absoluteMargin The absolute margin amount
 * @returns The calculated sales value
 */
export const calculateSalesValue = (
  purchaseValue: number,
  absoluteMargin: number
): number => {
  if (isNaN(purchaseValue) || isNaN(absoluteMargin)) return 0

  return purchaseValue + absoluteMargin
}

/**
 * Finds the preferred quote index based on state priority
 * @param quotes Array of quotes to search through
 * @returns The index of the preferred quote
 */
export const findPreferredQuoteIndex = (quotes: QuoteData[]): number => {
  if (!quotes.length) return 0

  const preferredStates = [
    QuoteState.Draft,
    QuoteState.Confirmed,
    QuoteState.Sent,
  ]

  for (const state of preferredStates) {
    const index = quotes.findIndex(
      (quote: { state: QuoteState }) => quote.state === state
    )
    if (index !== -1) {
      return index
    }
  }

  return 0 // Default to first quote if no preferred state is found
}

/**
 * Converts display index (shown to user) to actual index (in quotes array)
 * @param displayIndex The display index
 * @param totalQuotes Total number of quotes
 * @returns The actual index in the array
 */
export const getActualIndexFromDisplayIndex = (
  displayIndex: number,
  totalQuotes: number
): number => {
  if (!totalQuotes) return 0
  return totalQuotes - 1 - displayIndex
}

/**
 * Converts actual index (in quotes array) to display index (shown to user)
 * @param actualIndex The actual index in the array
 * @param totalQuotes Total number of quotes
 * @returns The display index
 */
export const getDisplayIndexFromActualIndex = (
  actualIndex: number,
  totalQuotes: number
): number => {
  if (!totalQuotes) return 0
  return totalQuotes - 1 - actualIndex
}

/**
 * Creates a new price calculation with updated margins
 * @param baseCalculation The base price calculation to update
 * @param nrcAbsoluteMargin NRC absolute margin
 * @param mrcAbsoluteMargin MRC absolute margin
 * @returns Updated price calculation
 */
export const createUpdatedPriceCalculation = (
  baseCalculation: PriceCalculation,
  nrcAbsoluteMargin: number,
  mrcAbsoluteMargin: number
): PriceCalculation => {
  if (!baseCalculation) return {} as PriceCalculation

  const purchaseValueNrc = baseCalculation.purchaseValueNrc || 0
  const purchaseValueMrc = baseCalculation.purchaseValueMrc || 0

  const nrcRelativeMargin = calculateRelativeMargin(
    purchaseValueNrc,
    nrcAbsoluteMargin
  )
  const mrcRelativeMargin = calculateRelativeMargin(
    purchaseValueMrc,
    mrcAbsoluteMargin
  )

  const salesValueNrc = calculateSalesValue(purchaseValueNrc, nrcAbsoluteMargin)
  const salesValueMrc = calculateSalesValue(purchaseValueMrc, mrcAbsoluteMargin)

  return {
    ...baseCalculation,
    marginAbsoluteNrc: nrcAbsoluteMargin,
    marginAbsoluteMrc: mrcAbsoluteMargin,
    marginRelativeNrc: nrcRelativeMargin,
    marginRelativeMrc: mrcRelativeMargin,
    salesValueNrc,
    salesValueMrc,
  }
}
