import React from 'react'
import {
  Edit,
  EditProps,
  BooleanInput,
  TextInput,
  required,
  AutocompleteInput,
  FormTab,
  ReferenceInput,
  TabbedForm,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
} from 'react-admin'
import { JsonInput } from 'react-admin-json-view'

import { Typography } from '@mui/material'
import { ReferenceAutocomplete } from '../../molecules/ReferenceAutocomplete'
import { FormTabLabelBySource } from '../../atoms/FormTab'
import { DualListInput } from '@react-admin/ra-relationships'

/**
 * Page to edit an existing Vendor.
 * @param props The page props.
 * @returns JSX.Element to render.
 */
export const VendorEdit: React.FC<EditProps> = (props) => {
  return (
    <Edit {...props} mutationMode="undoable">
      <TabbedForm>
        <FormTab label={'general'}>
          <TextInput source="name" validate={required()} fullWidth />
          <ReferenceInput
            source={'entityId'}
            reference="entities/list"
            emptyText={'Default'}
          >
            <AutocompleteInput
              optionText="name"
              label={'GNX Entity'}
              fullWidth
            />
          </ReferenceInput>
          <BooleanInput source="isActive" defaultValue={true} fullWidth />
        </FormTab>
        <FormTab label={'contact'}>
          <TextInput source="address" label="Full address" fullWidth />
          <TextInput source="postalCode" fullWidth />
          <ReferenceInput
            source={'countryCode'}
            reference="countries/list"
            emptyText={'N/A'}
          >
            <AutocompleteInput optionText="name" label={'Country'} fullWidth />
          </ReferenceInput>
          <TextInput source="city" fullWidth />
          <TextInput source="phoneNumber" fullWidth />
          <TextInput source="email" fullWidth />
        </FormTab>
        <FormTab label={'financial'}>
          <TextInput source="companyName" fullWidth />
          <TextInput
            source="companyRegNo"
            label="Business registration number"
            fullWidth
          />
          <TextInput
            source="companyIban"
            label="Bank account number"
            fullWidth
          />
          <TextInput
            source="companyVatNo"
            label="Taxes registration number"
            fullWidth
          />
          <NumberInput
            source="purchaseTaxRate"
            min="0"
            max="100"
            label="Tax rate on purchases (%)"
            fullWidth
          />
          <Typography>
            The settings below are used for sales tax calculations on the
            purchase price of products offered by this vendor
          </Typography>
          <ReferenceAutocomplete
            label="Tax country of origin (seller side)"
            source={'taxOriginCountryCode'}
            reference="countries/list"
            fullWidth
          />
          <ReferenceAutocomplete
            label="Tax target country (buyer side)"
            source={'taxTargetCountryCode'}
            reference="countries/list"
            defaultValue={'NL'}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.taxTargetCountryCode ? (
                <ReferenceAutocomplete
                  source={'taxTargetStateCode'}
                  reference={`countries/${formData.taxTargetCountryCode}/states`}
                  disableSyncWithLocation
                  label="Tax target state (buyer side)"
                  fullWidth
                />
              ) : null
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab
          label={
            <FormTabLabelBySource label="lmps" source="lastMileProviderIds" />
          }
        >
          <ReferenceArrayInput
            label="Last Mile Providers"
            source="lastMileProviderIds"
            reference="lastMileProviders"
          >
            <DualListInput optionText="name" label={false} />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab
          label={
            <FormTabLabelBySource
              label="isps"
              source="internetServiceProviderIds"
            />
          }
        >
          <ReferenceArrayInput
            label="Internet Service Providers"
            source="internetServiceProviderIds"
            reference="internetServiceProviders/list"
          >
            <DualListInput optionText="name" label={false} />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label={'api'}>
          <BooleanInput
            source="apiConfig.isActive"
            label={'Enable API integration'}
            defaultValue={false}
          />
          <TextInput
            source="apiConfig.queuePrefix"
            label={'Queue prefix to use for requests'}
            fullWidth
          />
          <TextInput
            source="apiConfig.queueKey"
            label={'Queue key to use for requests (overrides prefix)'}
            fullWidth
          />
          <BooleanInput
            source="apiConfig.supportsAvailabilityRequest"
            label={'Supports availability requests'}
            defaultValue={false}
          />
          <JsonInput
            source="apiConfig.availabilityRequestExtras"
            label="Availability request extras"
            defaultValue={{}}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
