import { memo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import { TextInput } from 'react-admin'
import ExpandAccordionIcon from '@mui/icons-material/ExpandMore'
import { CustomerNotesSectionProps } from './ProductSalesMarginTypes'

/**
 * Component that renders the customer notes section
 * Shows an expandable accordion with a text input for notes
 */
export const CustomerNotesSection: React.FC<CustomerNotesSectionProps> = memo(
  ({ notesForClient, isEditable, onChangeNotesForClient }) => {
    return (
      <Accordion
        defaultExpanded={!!notesForClient}
        elevation={0}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandAccordionIcon color="info" />}>
          <Typography variant="subtitle1">Notes for customer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextInput
            multiline
            source="notesForClient"
            disabled={!isEditable}
            variant="outlined"
            label={false}
            fullWidth
            onChange={onChangeNotesForClient}
            InputProps={{
              value: notesForClient,
            }}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
)
