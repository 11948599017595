import React from 'react'
import {
  Show,
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  DateField,
  TabbedShowLayoutTabs,
  FunctionField,
} from 'react-admin'
import { MutationsList } from '../../organisms/MutationsList'
import { PageTitle } from '../../atoms/PageTitle'
import { IPageMap, RelatedResourcesPage } from '../../molecules/RelatedResource'
import { ClientContractList } from '../ClientContract/List.component'
import { ClientList } from '../Client/List.component'
import { VendorContractList } from '../VendorContract/List.component'
import { VendorList } from '../Vendor/List.component'

/**
 * Page to show a single Entity
 * @property {ShowProps} props
 * @returns {React.FC<ShowProps>}
 * @example
 * <EntityShow {...props} />
 * @see https://marmelab.com/react-admin/Show.html
 */
export const EntityShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={<PageTitle prefix="Entity" />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="general">
          <TextField source="name" fullWidth />
          <TextField source="address" emptyText="N/A" fullWidth />
          <TextField source="companyName" emptyText="N/A" fullWidth />
          <TextField source="companyRegNo" emptyText="N/A" fullWidth />
          <TextField source="companyVatNo" emptyText="N/A" fullWidth />
          <TextField source="companyIban" emptyText="N/A" fullWidth />
          <DateField source="updatedAt" showTime />
          <DateField source="createdAt" showTime />
        </Tab>
        <Tab label="history">
          <MutationsList showActor type="history" />
        </Tab>
        <Tab label="related">
          <FunctionField
            render={(
              entity: Parameters<typeof getRelatedResourceNameToComponentMap>[0]
            ) => (
              <RelatedResourcesPage
                resource="entities"
                pages={getRelatedResourceNameToComponentMap(entity)}
              />
            )}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

/**
 * Returns a map of related resources to their respective components.
 * Used by the RelatedResourcesPage component.
 */
function getRelatedResourceNameToComponentMap(entity: {
  id: string
}): IPageMap {
  return {
    Clients: (
      <ClientList
        children={undefined}
        resource="clients"
        filter={{ entityId: entity.id }}
      />
    ),
    Vendors: (
      <VendorList
        children={undefined}
        resource="vendors"
        filter={{ entityId: entity.id }}
      />
    ),
    'Client Contracts': (
      <ClientContractList
        children={undefined}
        resource="clientContracts"
        filter={{ entityId: entity.id }}
      />
    ),
    'Vendor Contracts': (
      <VendorContractList
        children={undefined}
        resource="vendorContracts"
        filter={{ entityId: entity.id }}
      />
    ),
  }
}
