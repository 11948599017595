import { memo } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Divider,
  InputAdornment,
} from '@mui/material'
import { TextField, NumberInput } from 'react-admin'
import { CurrencyField } from '../../atoms/CurrencyField'
import { PriceCalculation } from './ProductSalesMarginTypes'

/**
 * Props for the generic PriceTable component
 */
interface PriceTableProps {
  /**
   * Type of price table (NRC or MRC)
   */
  type: 'NRC' | 'MRC'

  /**
   * Budgetary data for price calculations
   */
  budgetaryData: PriceCalculation

  /**
   * Firm data for price calculations
   */
  firmData: PriceCalculation

  /**
   * Whether the table is editable
   */
  isEditable: boolean

  /**
   * Handler for absolute margin changes
   */
  onChangeAbsoluteMargin: (event: any) => void

  /**
   * Handler for relative margin changes
   */
  onChangeRelativeMargin: (event: any) => void

  /**
   * Handler for when a margin field loses focus
   */
  onBlurMarginField: () => void
}

/**
 * Component that renders a generic price table for either NRC or MRC
 * Shows purchase values, margin inputs, and sales values
 * Can be configured for either Non-Recurring Charges or Monthly Recurring Charges
 */
export const PriceTable: React.FC<PriceTableProps> = memo(
  ({ type, isEditable, onChangeAbsoluteMargin, onChangeRelativeMargin, onBlurMarginField }) => {
    // Determine field names based on type (NRC or MRC)
    const purchaseTotalField =
      type === 'NRC' ? 'purchaseTotalNrc' : 'purchaseTotalMrc'
    const purchaseValueField =
      type === 'NRC' ? 'purchaseValueNrc' : 'purchaseValueMrc'
    const salesValueField = type === 'NRC' ? 'salesValueNrc' : 'salesValueMrc'
    const marginAbsoluteField =
      type === 'NRC' ? 'marginAbsoluteNrc' : 'marginAbsoluteMrc'
    const marginRelativeField =
      type === 'NRC' ? 'marginRelativeNrc' : 'marginRelativeMrc'

    return (
      <TableContainer component={Box}>
        <Table>
          <TableBody
            sx={{
              'td, th': {
                border: 0,
                padding: '6px 16px',
              },
            }}
          >
            <TableRow>
              <TableCell component="th" scope="row" width={'30%'}>
                <Typography fontWeight="bold" color="GrayText">
                  {type}
                </Typography>
              </TableCell>
              <TableCell width={'25%'}>
                <Typography variant="body1" color="GrayText">
                  Budgetary
                </Typography>
              </TableCell>
              <TableCell width={'45%'}>
                <Typography variant="body1" color="GrayText">
                  Firm
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body2" fontWeight="bold">
                  Purchase (local)
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <CurrencyField
                  currency="budgetary.purchaseCurrency"
                  variant="body2"
                  source={`budgetary.${purchaseTotalField}`}
                  emptyText="N/A"
                />
              </TableCell>
              <TableCell>
                <CurrencyField
                  currency="firm.purchaseCurrency"
                  variant="body2"
                  source={`firm.${purchaseTotalField}`}
                  emptyText="N/A"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body2" fontWeight="bold">
                  Purchase (EUR)
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <CurrencyField
                  currency="budgetary.valueCurrency"
                  variant="body2"
                  source={`budgetary.${purchaseValueField}`}
                  emptyText="N/A"
                />
              </TableCell>
              <TableCell>
                <CurrencyField
                  currency="firm.valueCurrency"
                  variant="body2"
                  source={`firm.${purchaseValueField}`}
                  emptyText="N/A"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body2" fontWeight="bold">
                  Margin
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 150 }}>
                <CurrencyField
                  currency="EUR"
                  variant="body2"
                  source={`budgetary.${marginAbsoluteField}`}
                  emptyText="N/A"
                />
                &nbsp;
                <Typography variant="caption">
                  (
                  <TextField
                    variant="caption"
                    source={`budgetary.${marginRelativeField}`}
                    emptyText="N/A"
                  />
                  %)
                </Typography>
              </TableCell>
              <TableCell>
                <NumberInput
                  disabled={!isEditable}
                  helperText={false}
                  size="small"
                  source={`firm.${marginAbsoluteField}`}
                  label={false}
                  variant="outlined"
                  onChange={onChangeAbsoluteMargin}
                  onBlur={onBlurMarginField}
                  sx={{ mr: 1, width: '45%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ m: 0 }}>
                        &euro;
                      </InputAdornment>
                    ),
                  }}
                />
                <NumberInput
                  disabled={!isEditable}
                  helperText={false}
                  size="small"
                  source={`firm.${marginRelativeField}`}
                  label={false}
                  variant="outlined"
                  fullWidth
                  onChange={onChangeRelativeMargin}
                  onBlur={onBlurMarginField}
                  sx={{ width: '45%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ m: 0 }}>
                        %
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>
                <Divider />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography variant="body2" fontWeight="bold">
                  Sales (EUR)
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <CurrencyField
                  currency="budgetary.valueCurrency"
                  variant="body2"
                  source={`budgetary.${salesValueField}`}
                  emptyText="N/A"
                />
              </TableCell>
              <TableCell>
                <CurrencyField
                  currency="budgetary.valueCurrency"
                  variant="body2"
                  source={`firm.${salesValueField}`}
                  emptyText="N/A"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)
